import { render, staticRenderFns } from "./AbstractSubmissionGuideline.vue?vue&type=template&id=5e853279&scoped=true"
import script from "./AbstractSubmissionGuideline.vue?vue&type=script&lang=js"
export * from "./AbstractSubmissionGuideline.vue?vue&type=script&lang=js"
import style0 from "./AbstractSubmissionGuideline.vue?vue&type=style&index=0&id=5e853279&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e853279",
  null
  
)

export default component.exports