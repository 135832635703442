<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='border-2 border-gray-100 px-4 lg:px-8 py-8 rounded-lg shadow-md text-center font-light'>
      <p class='text-lg lg:text-xl'>Abstract submission deadline is <span :style='`color: ${eventMainThemeColor};`' class='block lg:inline text-xl lg:text-2xl font-bold'>May 23 (Mon), 2022</span></p>
      <div class='mx-auto text-base lg:text-lg mt-4 underline'>
        <router-link :to='{ name: "AbstractSubmissions" }'>Go to Abstract Submission</router-link>
      </div>
    </div>
    <div class='mt-8 flex flex-row justify-end gap-x-2'>
      <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/abstract-submission-guideline/2022KOA_Abstract_Sample.docx'
        :style='`background-color: ${eventMainThemeColor};`'
        class='text-sm lg:text-base text-white font-semibold px-4 py-2'>
        Abstract Sample
      </a>
      <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/abstract-submission-guideline/2022KOA_Abstract_Submission_Guideline_%E1%84%80%E1%85%AE%E1%86%A8%E1%84%86%E1%85%AE%E1%86%AB.pdf'
        target='_blank'
        :style='`background-color: ${eventMainThemeColor};`'
        class='text-sm lg:text-base text-white font-semibold px-4 py-2'>
        국문 가이드 라인
      </a>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>
        Abstract Guideline
      </h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            The abstract submission guidelines of the 66<sup>th</sup> Annual Hybrid Congress of the Korean Orthopaedic Association are intended to provide clear instructions for submitting an abstract. Please carefully review the guidelines below before submitting your abstract(s).<br>
            Abstract(s) must be submitted online at our website at <a href='https://2022.koa.or.kr/' target='_blank'  class='underline'>https://2022.koa.or.kr</a> If all sections are not completed, your abstract submission will NOT be accepted.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Important Date</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Abstract Submission Deadline : May 23 (Mon), 2022
            <div class='text-sm text-blue-900 opacity-75 ml-6'>* Based on Korean Standard Time (GMT +9hrs.)</div>
          </li>
          <li class='mt-2 mb-8'>
            Notifications of Acceptance : June 16 (Thu), 2022
            <div class='text-sm text-blue-900 opacity-75 ml-6'>* Based on Korean Standard Time (GMT +9hrs.)</div>
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Abstract Submission Process</h2>
      <div class='mt-2 w-full lg:w-2/3 pl-6 lg:pl-0'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/abstract-submission-guideline/abstract-submission-guideline_1.png' class='w-full'>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Guidelines for Abstract Submissions</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Please kindly be reminded that all submitted abstracts must be written in English only. Speakers must submit their abstract(s) in accordance with the submission process outlined on our website and using their personal website ID.
          </li>
          <li class='mt-2 mb-8'>
            Presenting author must attend at The 66th Annual Hybrid Congress of the Korean Orthopaedic Association
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Abstract Guidelines for Free Paper(Oral & Poster & Video)</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Abstracts should be written in English in a grammatically correct manner.
          </li>
          <li class='mt-2 mb-8'>
            The official language of the presentation is <span class='font-semibold' :style='`color: ${eventMainThemeColor};`'>English</span>.
          </li>
          <li class='mt-2 mb-8'>
            It is not allowed to modify abstracts in any way after submission deadline.
          </li>
          <li class='mt-2 mb-8'>
            Linguistic accuracy is the responsibility of the authors.
          </li>
          <li class='mt-2 mb-8'>
            Abstract Titles
            <ul class='pl-4'>
              <li>
                - The abstract title should be short, informative and contain the major key words.
              </li>
              <li>
                - The first letters of all words except for articles, prepositions, and conjunctions, should be in capital letters. All other letters should be in lower case.
              </li>
            </ul>
          </li>
          <li class='mt-2 mb-8'>
            Abstracts are limited to 450 words in English
            <ul class='pl-4'>
              <li>
                - Abstracts should be structured into the following headings: Introduction, Materials & Methods, Results and Conclusion.
              </li>
              <li>
                - Figures, tables, graphs and images are not allowed.
              </li>
              <li>
                - References and keywords must not be included.
              </li>
            </ul>
          </li>
          <li class='mt-2 mb-8'>
            Names and affiliations of authors will be listed. Please enter the full name in the order of First name and Last name.
          </li>
          <li class='mt-2 mb-8'>
            Be sure to list the names of all co-authors, including the presenter, to prevent any omissions in the abstract file.
          </li>
          <li class='mt-2 mb-8'>
            Please enter the name of presenter, name of corresponding author, and the name of all authors are required. Please enter the affiliation in order of authors.
          </li>
          <li class='mt-2 mb-8'>
            If an author is affiliated with a hospital as well as university, please enter the name of the hospital for which he/she works.
          </li>
          <li class='mt-2 mb-8'>
            Please describe if it belongs to the Conflict of Interest. This is when authors are provided with more than KRW 500,000(USD 500) or any corresponding value from a certain company or institution and the abstracts they have submitted are directly/indirectly associated. This includes research funds support (money or goods), patent fees, consultancy fees, stockholding, employees, etc.
          </li>
          <li class='mt-2 mb-8'>
            The deadline for abstract submission is May 23 (Mon), 2022
          </li>
          <li class='mt-2 mb-8'>
            Please make sure to fill in the correct contact phone number, mobile phone number and e-mail address of the presenter.
          </li>
          <li class='mt-2 mb-8'>
            Presenters and corresponding author will receive an e-mail confirming the receipt of the abstract(s). In the case that a confirmation email is not received within 24 hours, please contact the secretariat.
          </li>
          <li class='mt-2 mb-8'>
            ‘Oral presentation or poster’ means that it can be transferred to poster if the abstract is disqualified for oral abstract screening.
          </li>
          <li class='mt-2 mb-8'>
            Find abstract category 2 (original article, case report, and technical note). If you have selected oral presentation in abstract category 1, you must choose the original article only. You can only select poster and video presentation for case report and technical note.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Notification of Acceptance</h2>
      <div class='w-full lg:w-2/3'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 pl-6 lg:pl-0'>
          <li class='mt-2 mb-8'>
            Acceptance notifications will be sent to presenter on June 16 (Thu), 2022 by e-mail.
          </li>
          <li class='mt-2 mb-8'>
            The decision of the Scientific Committee on the acceptability of the abstract is final. While an author’s preferences for either an oral or poster presentation will be considered, the committee may suggest an alternative format. Authors can not dispute the committee’s final decision.
          </li>
          <li class='mt-2 mb-8'>
            The presenting author must reply to the acceptance notification in order to acknowledge receipt and confirm attendance.
          </li>
        </ul>
        <table class='mt-2 mb-8 abstract-submission-guideline-table border-t border-l text-center text-sm lg:text-base'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/4'></th>
                <th class='w-1/4'>Oral Presentation <br> (English)</th>
                <th class='w-1/4'>Poster</th>
                <th class='w-1/4 '>Video</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Limits for abstract submissions</th>
                <td>There is no limit on the number of submissions. For domestic submitters, however, please refer to the Korean guidelines.</td>
                <td>Up to 3 episodes as first author or corresponding author</td>
                <td>(None)</td>
              </tr>
              <tr class='bg-gray-100'>
                <th>May 23 (Mon)</th>
                <td colspan='3'>Deadline to submit abstract</td>
              </tr>
              <tr>
                <th>Jun 16 (Thu)</th>
                <td colspan='3'>Acceptance Notification</td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Jun 30 (Thu)</th>
                <td colspan='3'>Last day to cancel presentations*</td>
              </tr>
              <tr>
                <th>Aug 12 (Fri)</th>
                <td>Submit completed papers or extended abstracts**</td>
                <td colspan='2'>(None)</td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Sep 30 (Fri)</th>
                <td>Presentation slides submission deadline</td>
                <td>PDF file upload deadline</td>
                <td>Submit video files to display***</td>
              </tr>
            </tbody>
          </table>
          <div class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            *If you cancel your presentation after the last day to cancel presentations, you will get restrictions on presentations in next congress.
          </div>
          <div class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2'>
            **Please submit completed papers or extended abstracts before presentations. If they are not submitted, you will not be allowed to have oral presentations as the first author or corresponding author for the next 1 year. Please strictly observe the deadline.
          </div>
          <div class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 mt-2 mb-8'>
             ***We receive video files to screen them in advance. Please note that you will not be able to edit your files after you submit them.
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AbstractSubmissionGuideline',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>

<style type='scss' scoped>
  .abstract-submission-guideline-table tr {
    @apply border-b
  }

  .abstract-submission-guideline-table td {
    @apply border-r p-1 lg:p-2
  }

  .abstract-submission-guideline-table th {
    @apply border-r p-1 lg:p-2
  }
</style>
